import React, { Component } from "react";
import moment from 'moment';
import 'moment/locale/th';
import {
	Link,
} from "react-router-dom";

import authFetch from '../../services/authFetch';
import Loading from '../../components/Loading';

import Logout from '../../components/Logout';
import CardReader from '../../components/CardReader';
import PassportReader from '../../components/PassportReader';
import ProgramSelect from '../../components/ProgramSelect';
import PackageSelect from '../../components/PackageSelect';
import BirthdaySelect from '../../components/BirthdaySelect';
import ShowValidate from '../../components/ShowValidate';

import nativePrint from '../../helpers/NativePrint';

import { Translate } from "react-localize-redux";
import validate from "validate.js";

class RegisterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vn: this.props.match.params.vn,
			patient: {
				age: "",
				cid: "",
				dob: "",
				eid: "",
				prefix: "",
        other_prefix: "",
				firstname: "",
				lastname: "",
				address: "",
				id: "",
				sex: "",
				tel: "",
				card_id: "",
				card_type: "cid",
				position: "",
				department: "",
        nationality_id: "",
        birthplace: "",
        job: ""
			},

      name_prefixs: [
        {value:"นาย",title:"นาย"},
        {value:"นาง",title:"นาง"},
        {value:"น.ส.",title:"น.ส."},
        {value:"ด.ญ.",title:"ด.ญ."},
        {value:"ด.ช.",title:"ด.ช."},
        {value:"other",title:"อื่นๆ"}
      ],

			programs: [],
			programs_labs: [],
			packages: [],
			packages_labs: [],
			add_on_labs: [],
			registered_at: null,
			reader_patient: null,
      nationalities: [],
			companies: [],
			all_packages: [],
			all_programs: [],
			all_labs: [],

			saved: false,
			loading: true,
			errors: {},
			ready: false,
      is_dirty: false
		};

		this.handlePatientChange = this.handlePatientChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleProgramChange = this.handleProgramChange.bind(this);
		this.handlePackageChange = this.handlePackageChange.bind(this);
		this.updateFromCard = this.updateFromCard.bind(this);
		this.doSubmit = this.doSubmit.bind(this);
		this.afterSave = this.afterSave.bind(this);
		this.validateData = this.validateData.bind(this);
		this.getReaderFromLocation = this.getReaderFromLocation.bind(this);
		this.buildProgramLabs = this.buildProgramLabs.bind(this);
		this.setPatient = this.setPatient.bind(this);
		this.printStickers = this.printStickers.bind(this);
		this.goBack = this.goBack.bind(this);
		this.doGoBack = this.doGoBack.bind(this);
    this.afterPrint = this.afterPrint.bind(this);
    this.deRegister = this.deRegister.bind(this);

		this.ctrlSubmit = this.ctrlSubmit.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.ctrlSubmit, false);
	}

	ctrlSubmit(e) {
		if (e.ctrlKey && e.keyCode === 13) {
			// Ctrl-Enter pressed
			this.doSubmit(e);
		}
		return;
	}

	componentDidMount() {
    //this.printStickers([{'type':'app_qr',url:"https://ckme.cc/dc894646afdd60553f41d7dc"}]);
		document.addEventListener("keydown", this.ctrlSubmit, false);
		if (this.state.vn !== undefined) {
			authFetch.get('visit/' + this.state.vn, (result) => {
				if (result.success) {
					var programs = result.programs.map((item) => { return item.id });
					var patient = { ...this.state.patient, ...result.patient };
					if (patient.other_id) {
						patient.card_type = "other_id";
						patient.card_id = patient.other_id;
					} else if (patient.cid) {
						patient.card_type = "cid";
						patient.card_id = patient.cid;
					}
          if(this.isOtherPrefix(patient.prefix)){
            patient.other_prefix = patient.prefix;
            patient.prefix = 'other';
          }
					patient.cid = "";
					patient.other_id = "";
					this.setState({
						vn: result.vn,
						patient: patient,
						programs: programs,
						packages: result.packages.map((item) => { return item.id }),
						add_on_labs: result.add_on_labs.map((item) => { return item.id }),
						registered_at: result.registered_at
					});

					this.buildProgramLabs(programs);
				}
				this.setState({ loading: false,ready: true });
				this.getReaderFromLocation();
			});
		} else {
			this.setState({ loading: false,ready: true });
			this.getReaderFromLocation();
		}

		authFetch.get('nationalities', (result) => {
			//console.log(result)
			if (result.success) {
				this.setState({ nationalities: result.nationalities });
			}
		});

		authFetch.get('programs', (result) => {
			//console.log(result)
			if (result.success) {
				this.setState({ all_programs: result.programs });
				this.buildProgramLabs(this.state.programs);
			}
		});

		authFetch.get('packages', (result) => {
			//console.log(result)
			if (result.success) {
				this.setState({ all_packages: result.packages });
			}
		});

		authFetch.get('companies', (result) => {
			if (result.success) {
				this.setState({ companies: result.companies });
			}
		});

		this.setState({ all_branches: [{ id: 1, name: 'b1' }] });
	}

  isOtherPrefix(prefix) {
    let result = true
    this.state.name_prefixs.map((item)=>{
      if(item.value===prefix)result=false
    })
    return result
  }

	getReaderFromLocation() {
		if (this.props.location.state && this.props.location.state.reader_data) {
			this.updateFromCard(this.props.location.state.reader_data);
		}
	}

	handlePatientChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		const name = target.name;
		this.setState({ patient: { ...this.state.patient, ...{ [name]: value } } }, this.validateData);
    this.setState({is_dirty: true});
	}

	setPatient(obj) {
    if(obj.prefix) {
      if(this.isOtherPrefix(obj.prefix)){
        obj.other_prefix = obj.prefix;
        obj.prefix = 'other';
      }
    }
		this.setState({ patient: { ...this.state.patient, ...obj } }, this.validateData);
	}

	handleDateChange(event) {
		var result = { dob: this.state.patient.dob, age: this.state.patient.age };
		result.dob = event;
		if (event) {
			var age = moment().diff(event, 'years');
			result.age = age;
		}
		this.setState({ patient: { ...this.state.patient, ...result } }, this.validateData);
    this.setState({is_dirty: true});
	}

	handleProgramChange(results) {
		this.setState({ programs: results }, this.validateData);
		this.buildProgramLabs(results);
    this.setState({is_dirty: true});
	}

	handlePackageChange(packages, labs) {
		this.setState({ packages: packages, add_on_labs: labs });
    this.setState({is_dirty: true});
	}

	updateFromCard(data) {
		var patient = {};
		if (!this.state.patient.card_id) {
			if(data.nationality && data.nationality!=='THA') {
				patient.card_id = data.doc_no;
				patient.card_type = "other_id";
			} else {
				patient.card_id = data.cid;
				patient.card_type = "cid";
			}
		}
		//if (!this.state.patient.fullname) patient.fullname = data.fullname;
		if (!this.state.patient.prefix) {
      if(this.isOtherPrefix(data.prefix)){
        patient.prefix = 'other';
        patient.other_prefix = data.prefix;
      } else {
        patient.prefix = data.prefix;
      }
    }
		if (!this.state.patient.firstname) patient.firstname = data.firstname;
		if (!this.state.patient.lastname) patient.lastname = data.lastname;
		if (!this.state.patient.sex) patient.sex = data.gender;
		if (!this.state.patient.dob) patient.dob = data.dob;
		if (!this.state.patient.address) patient.address = data.address;
		if (!this.state.patient.nationality_id) patient.nationality_id = data.nationality_id;
		//if (!this.state.patient.birthplace) patient.birthplace = 'data.address';

		this.setState({ reader_patient: data, patient: { ...this.state.patient, ...patient } });
    this.setState({is_dirty: true});
	}

	buildProgramLabs(programs) {
		var programs_labs = [];
		this.state.all_programs.map((item, index) => {
			if (programs.includes(item.id)) {
				programs_labs = programs_labs.concat(item.labs.map(function (elem) { return elem.id; }));
			}
		});

		programs_labs = programs_labs.filter((value, index, self) => { return self.indexOf(value) === index; });

		this.setState({ programs_labs: programs_labs });
	}

	doSubmit(e) {
		//console.log('dosubmit')
		if(e){e.preventDefault();}
		if (this.validateData(true)) {
			//console.log('validated')
			this.setState({ loading: true });
			//console.log('setloading')
			//return;

			var patient = this.state.patient;
			if (patient.card_type == "cid") {
				patient.cid = patient.card_id;
				patient.other_id = "";
			} else {
				patient.other_id = patient.card_id;
				patient.cid = "";
			}
      if(patient.prefix==="other") {
        patient.prefix = patient.other_prefix
      }

			var data = {};
			data = { ...patient, ...{ programs: this.state.programs } };

			data.packages = this.state.packages;
			data.labs = this.state.add_on_labs;
			if (this.state.vn !== undefined) {
				authFetch.put('patient/' + this.state.vn, data, (result) => { 
					this.afterSave(result)
				});
			} else {
				//create
				authFetch.post('patient', data, (result) => { this.afterSave(result) });
			}
		}
		return false;
	}

	validateData(force) {
		if (force !== true && validate.isEmpty(this.state.errors)) {
			return false;
		} else {

		}
		var errors = {};
		validate.extend(validate.validators.datetime, {
			// The value is guaranteed not to be null or undefined but otherwise it
			// could be anything.
			parse: function (value, options) {
				return +moment.utc(value);
			},
			// Input is a unix timestamp
			format: function (value, options) {
				var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
				return moment.utc(value).format(format);
			}
		});

		var patient_constraints = {
			// firstname: {
			// 	presence: true,
			// 	length: {
			// 		minimum: 3,
			// 		message: "must be at least 3 characters"
			// 	}
			// },
			sex: {
				presence: true,
				inclusion: {
					within: ["male", "female"],
					message: "is invalid"
				}
			},
			dob: {
				presence: true,
				datetime: {
					dateOnly: true,
					message: "is invalid"
				}
			},
		};
		var invalids = validate(this.state.patient, patient_constraints);
		if (invalids !== undefined) errors = { ...errors, ...invalids };
		if (this.state.programs.length == 0) {
			//errors = {...errors,...{programs:['program is required']}};
		}

		this.setState({ errors: errors });
		if (validate.isEmpty(errors)) {
			return true;
		} else {
			return false;
		}
	}

	afterSave(result) {
		if (result.success) {
			if (result.success) {
				if (result.stickers && result.stickers.length > 0) {
					this.printStickers(result.stickers);
				} else {
					this.doGoBack();
				}
			}
		} else {
			if (result.errors) window.doAlert(result.errors.join('\n'));
			else {
				window.doAlert("Server Response Error");
			}
		}

		this.setState({ loading: false });
  }
  
  deRegister() {
    window.doConfirm( 
      "confirm_deregister",
      ()=>{
        authFetch.put('visit/'+this.state.vn+'/de-register', {}, (result) => {
          if (result.success) {
            this.doGoBack();
          } else {
            this.setState({ loading: false });
            window.doConfirm('saved_error_save_print_status_exit', this.goBack, () => { });
          }
        });
      },
      ()=>{}
    )
  }

	printStickers(stickers) {
		if (stickers.length > 0) {
			nativePrint(stickers, this.afterPrint);
		}
	}

	afterPrint(success, stickers) {
		if (success) {
			var specimens = stickers.map((item, index) => { return item.id });
			var data = { specimens: specimens }
			authFetch.put('visit/stickers', data, (result) => {
				if (result.success) {
					this.doGoBack();
				} else {
					this.setState({ loading: false });
					window.doConfirm('saved_error_save_print_status_exit', this.goBack, () => { });
				}
			});
		} else {
			this.setState({ loading: false });
			window.doConfirm('saved_error_printing_exit', this.goBack, () => { });
		}
	}

	goBack() {
    if(this.state.is_dirty) {
      window.doConfirm('there_has_been_som_changes_do_you_want_to_save', ()=>{this.doSubmit(false)},this.doGoBack);
    } else {
      this.doGoBack()
    }
	}

  doGoBack() {
		var backTo = '/register';
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
  }

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="register-page">
						<div className="page-header">
							<button className="link-page-back" onClick={() => this.goBack()}>{translate("back")}</button>
							{this.state.vn ?
								(
									<h1>{this.state.registered_at ? (<span>{translate("edit_patients")}</span>) : (<span>{translate("register")}</span>)}</h1>
								) : (
									<h1>{translate("add_patients")}</h1>
								)
							}
							<Logout />
						</div>
						<div>
							<div className="page-content">
								<div className="page-content-main">
									<table className="form-table form-table-radius">
										<thead>
											<tr>
												<th colSpan="2">{translate("data_from_database")}</th>
												<th width="350">{translate("data_from_card")}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th width="200" className="text-right">{translate("name")} <span className="asterik">*</span></th>
												<td>
													<div className="table-input-row">
														<div className="table-input-column table-input-column-2">
                              <ShowValidate validate={this.state.errors.prefix} />
                              <div className="table-input-wrapper">
                                <select name="prefix" style={{width: '100%'}} value={this.state.patient.prefix} onChange={this.handlePatientChange}>
                                  <option value="">{translate('select_prefix')}</option>
                                  {this.state.name_prefixs.map((item, index) => {
                                    return <option key={index} value={item.value}>{item.title}</option>
                                  })}
                                </select>
                              </div>
														</div>
                            {(this.state.patient.prefix=='other') ? (
														<div className="table-input-column table-input-column-2">
                              <input className="table-input" type="text" name="other_prefix" placeholder="ระบุ"
                                value={this.state.patient.other_prefix} onChange={this.handlePatientChange} />
														</div>
                            ):null}
														<div className="table-input-column table-input-column-4">
                              <ShowValidate validate={this.state.errors.firstname} />
                              <input className="table-input" type="text" name="firstname"  placeholder="ชื่อ"
                                value={this.state.patient.firstname} onChange={this.handlePatientChange} />
														</div>
														<div className="table-input-column table-input-column-4">
                              <ShowValidate validate={this.state.errors.lastname} />
                              <input className="table-input" type="text" name="lastname"  placeholder="นามสกุล"
                                value={this.state.patient.lastname} onChange={this.handlePatientChange} />
														</div>
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center">
														{this.state.reader_patient.prefix} {this.state.reader_patient.firstname} {this.state.reader_patient.lastname}
														<div className="card-reader-result">
															{( this.state.reader_patient.firstname === this.state.patient.firstname
                              && this.state.reader_patient.lastname === this.state.patient.lastname) ? (
																<i className="fas fa-check"></i>
															) : (
																	<button className="card-reader-result-use" onClick={() => this.setPatient(
                                    { prefix: this.state.reader_patient.prefix,
                                      firstname: this.state.reader_patient.firstname,
                                      lastname: this.state.reader_patient.lastname }
                                  )}>
																		<i className="fas fa-angle-double-left"></i>
																	</button>
																)
															}
														</div>
													</td>
												) : (
													<td className="left-border text-center" rowSpan="7">
														<p>{translate("no_data")}</p>
													</td>
												)}
											</tr>
											<tr>
												<th className="text-right">{translate("id_number")}</th>
												<td>
													<ShowValidate validate={this.state.errors.card_id} />

													<div className="table-input-row">
														<div className="table-input-column">
															<input className="table-input" type="text" name="card_id"
																value={this.state.patient.card_id} onChange={this.handlePatientChange} />
														</div>
														<div className="table-input-column">
															<div className="table-input-wrapper">
																<select name="card_type" value={this.state.patient.card_type} onChange={this.handlePatientChange}>
																	<option value="cid">{translate('thai_citizen_id')}</option>
																	<option value="other_id">{translate('other_id')}</option>
																</select>
															</div>
														</div>

                            
														<div className="table-input-column">
                              <div className="table-input-wrapper">
																<select name="nationality_id" style={{width: '160px'}} value={this.state.patient.nationality_id} onChange={this.handlePatientChange}>
																	<option value="">{translate('plase_select_nationality')}</option>
                                  {this.state.nationalities.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.name}</option>
                                  })}
																</select>
															</div>
														</div>
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center">
														{this.state.reader_patient.cid}
														<div className="card-reader-result">
															{(this.state.reader_patient.cid === this.state.patient.card_id) ? (
																<i className="fas fa-check"></i>
															) : (
																	<button className="card-reader-result-use" onClick={() => this.setPatient({ card_id: this.state.reader_patient.cid })}>
																		<i className="fas fa-angle-double-left"></i>
																	</button>
																)
															}
														</div>
													</td>
												) : (null) }
											</tr>
											<tr>
												<th className="text-right">{translate("address")}</th>
												<td>
													<input className="table-input" type="text" name="address"
														value={this.state.patient.address} onChange={this.handlePatientChange} />
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center">
														{this.state.reader_patient.address}
														<div className="card-reader-result">
															{(this.state.reader_patient.address === this.state.patient.address) ? (
																<i className="fas fa-check"></i>
															) : (
																	<button className="card-reader-result-use" onClick={() => this.setPatient({ address: this.state.reader_patient.address })}>
																		<i className="fas fa-angle-double-left"></i>
																	</button>
																)
															}
														</div>
													</td>
												) : (null)}
											</tr>
											<tr>
												<th className="text-right">{translate("employee_id")}</th>
												<td>
													<div className="table-input-row">
														<div className="table-input-column">
															<input className="table-input" type="text" name="eid"
																value={this.state.patient.eid} onChange={this.handlePatientChange} />
														</div>
														<div className="table-input-column">
															<input className="table-input" type="text" name="position" placeholder="ตำแหน่ง"
																value={this.state.patient.position} onChange={this.handlePatientChange} />
														</div>
															<div className="table-input-column">
																<input className="table-input" type="text" name="department" placeholder="แผนก"
																	value={this.state.patient.department} onChange={this.handlePatientChange} />
															</div>
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center"></td>
												) : (null)}
											</tr>
											<tr>
												<th className="text-right">{translate("company_branch")}</th>
												<td>
													<div className="table-input-wrapper">
														<ShowValidate validate={this.state.errors.branch_id} />
														<select name="branch_id" value={this.state.patient.branch_id} onChange={this.handlePatientChange}>
															<option value={""}>Please Select</option>
															{this.state.companies.map((item, index) => {
																return <option key={index} value={item.id}>{item.name}</option>
															})}
														</select>
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center"></td>
												) : (null)}
											</tr>
											<tr>
												<th className="text-right">{translate("birthday")} <span className="asterik">*</span></th>
												<td>
													<div className="table-input-wrapper">
														<ShowValidate validate={this.state.errors.dob} />
														<BirthdaySelect dateChange={this.handleDateChange} name="dob" value={this.state.patient.dob} />
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center">
														{this.state.reader_patient.dob}
														<div className="card-reader-result">
															{(this.state.reader_patient.dob === this.state.patient.dob) ? (
																<i className="fas fa-check"></i>
															) : (
																	<button className="card-reader-result-use" onClick={() => this.setPatient({ dob: this.state.reader_patient.dob })}>
																		<i className="fas fa-angle-double-left"></i>
																	</button>
																)
															}
														</div>
													</td>
												) : (null)}
											</tr>
											<tr>
												<th className="text-right">{translate("gender")} <span className="asterik">*</span></th>
												<td>
													<div className="table-input-wrapper">
														<ShowValidate validate={this.state.errors.sex} />
														<label className="table-radio">
															<input type="radio" value="male" name="sex" checked={this.state.patient.sex === "male"} onChange={this.handlePatientChange} /> {translate("male")}
														</label>
														<label className="table-radio">
															<input type="radio" value="female" name="sex" checked={this.state.patient.sex === "female"} onChange={this.handlePatientChange} /> {translate("female")}
														</label>
													</div>
												</td>
												{(this.state.reader_patient) ? (
													<td className="left-border text-center">
														{translate(this.state.reader_patient.gender)}
														<div className="card-reader-result">
															{(this.state.reader_patient.gender === this.state.patient.sex) ? (
																<i className="fas fa-check"></i>
															) : (
																	<button className="card-reader-result-use" onClick={() => this.setPatient({ sex: this.state.reader_patient.gender })}>
																		<i className="fas fa-angle-double-left"></i>
																	</button>
																)
															}
														</div>
													</td>
												) : (null)}
											</tr>
											<tr>
												<th className="text-right">{translate("phone_number")}</th>
												<td>
													<input className="table-input" type="text" name="tel"
														value={this.state.patient.tel} onChange={this.handlePatientChange} />
												</td>
												<td className="left-border text-center" rowSpan="2">
													<CardReader hidetext={true} autoRead={false} update={this.updateFromCard} />
													<PassportReader hidetext={true} autoRead={false} update={this.updateFromCard} />
												</td>
											</tr>
                      <tr>
												<th className="text-right">สถานที่เกิด</th>
                        <td>
													<div className="table-input-row">
														<div className="table-input-column">
                              <input className="table-input" type="text" name="birthplace" placeholder={translate('birthplace_province')}
                                value={this.state.patient.birthplace} onChange={this.handlePatientChange} />
														</div>
														<div className="table-input-column">
															<input className="table-input" type="text" name="color" placeholder={translate('skin_color')}
																value={this.state.patient.color} onChange={this.handlePatientChange} />
														</div>
														<div className="table-input-column">
															<input className="table-input" type="text" name="job" placeholder={translate('occupation')}
																value={this.state.patient.job} onChange={this.handlePatientChange} />
														</div>
													</div>
												</td>
                      </tr>
										</tbody>
									</table>
									<div className="register-form-footer">
										<div className="register-form-footer-table">
											<table className="form-table form-table-middle">
												<tbody>
													<tr>
														<th width="200" className="text-right">{translate("checkup_program")}</th>
														<td>
															<ProgramSelect patient={this.state.patient} programs={this.state.programs} update={this.handleProgramChange} all_programs={this.state.all_programs} />
															<ShowValidate validate={this.state.errors.programs} />
														</td>
													</tr>
													<tr>
														<td colSpan="2" className="text-center">
															<small>{translate("program_change_text_company")} (<strong>{translate("program_change_text_cost")}</strong>)</small>
														</td>
													</tr>
												</tbody>
											</table>
											<table className="form-table form-table-append">
												<tbody>
													<tr>
														<td>
															{this.state.ready?
																<PackageSelect patient={this.state.patient} packages={this.state.packages} all_labs={this.state.all_labs} labs={this.state.add_on_labs} update={this.handlePackageChange} all_packages={this.state.all_packages} programs_labs={this.state.programs_labs} />
															:null}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="register-form-footer-buttons text-center">
                      {(this.state.registered_at) ?
                        <div>
                          <p>
                            <strong className="register-form-status-registered">{translate("registered")}</strong> 
                            &nbsp;
                            
                            <button className="btn btn-round-sm"
                onClick={this.deRegister}>{translate("de_register")}</button>
                          </p>
                          <p>
                            <button className="btn btn-round-sm btn-block"
                  onClick={()=>{this.props.history.push('/management/visit/stickers/'+this.state.vn, { backTo: '/register' });}}>{translate("print_sticker")}</button>
                          </p>
                          <p>
                            <button onClick={(e) => this.doSubmit(e)} className="btn-round btn-block btn-forn-registered">
                              {translate("edit_data")} <br />& {translate("print_sticker")}
                            </button>
                          </p>
                        </div>
                        :
                        <div>
                          <p><strong className="register-form-status-not-registered">{translate("not_registered")}</strong></p>
                          <p>
                            <button onClick={(e) => this.doSubmit(e)} className="btn-round btn-block btn-forn-not-registered">
                              {translate("confirm_data")}{translate("register")} <br />& {translate("print_sticker")}
                            </button>
                          </p>
                        </div>
											}
										</div>
									</div>
								</div>
							</div>	
						</div>
						<Loading show={this.state.loading} />
					</div>
				}
			</Translate>
		);
	}
}

export default RegisterForm;
