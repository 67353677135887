const EventEmitter = require('events')
var pcsclite;
var isNative = false;
if (typeof (window.pcsclite) !== "undefined") {
  isNative = true;
  pcsclite = window.pcsclite; // eslint-disable-line no-undef
}

// command list
const _INIT_SELECT = [0x00, 0xA4, 0x04, 0x00, 0x08, 0xA0, 0x00, 0x00, 0x00, 0x54, 0x48, 0x00, 0x01]

const _SELECT1 = [0x00, 0xC0, 0x00, 0x00]
const _SELECT2 = [0x00, 0xc0, 0x00, 0x01]

let _SELECT = _SELECT1;

const _CID = [0x80, 0xb0, 0x00, 0x04, 0x02, 0x00, 0x0d]
const _PERSONNEL = [0x80, 0xb0, 0x00, 0x11, 0x02, 0x00, 0xd1]
const _THFULLNAME = [0x80, 0xb0, 0x00, 0x11, 0x02, 0x00, 0x64]
const _ENFULLNAME = [0x80, 0xb0, 0x00, 0x75, 0x02, 0x00, 0x64]
const _BIRTH = [0x80, 0xb0, 0x00, 0xD9, 0x02, 0x00, 0x08]
const _GENDER = [0x80, 0xb0, 0x00, 0xE1, 0x02, 0x00, 0x01]
const _ISSUER = [0x80, 0xb0, 0x00, 0xF6, 0x02, 0x00, 0x64]
const _ISSUE = [0x80, 0xb0, 0x01, 0x67, 0x02, 0x00, 0x08]
const _EXPIRE = [0x80, 0xb0, 0x01, 0x6F, 0x02, 0x00, 0x08]
const _ADDRESS = [0x80, 0xb0, 0x15, 0x79, 0x02, 0x00, 0x64]

class NativeCardReader extends EventEmitter {
  pcsc = null;
  reader = null;
  is_connecting = false;
  has_reader = false;
  current_status = {};
  constructor() {
    super();
    this.init();
  }

  init () {
    if(isNative){
      try {
        this.pcsc = pcsclite();
        this.pcsc_listening();
        this.read();
      } catch (e) {
        alert("ไม่พบเครื่องอ่านบัตรประชาชน หากต้องการใช้งานเครื่องอ่านบัตรฯ กรุณาเสียบเครื่องอ่านบัตรและปิดแล้วเปิดโปรแกรมใหม่");
        console.log(e);
      }
    }
  }

  read() {
    console.log('Read()');
    var _this = this;
    this.pcsc.on('reader', function (reader) {
      if(reader.name.startsWith('Microsoft')) {
        console.log('MS Reader. Exiting...')
        return;
      } else {
        _this.reader = reader;
        _this.has_reader = true;
        _this.emit('has_reader',true);
      }

      reader.on('status', function (status) {
        //console.log('Status(', this.name, '):', status);
        //_this.emit('READING_INIT',status);
        _this.current_status = status;
        var changes = this.state ^ status.state;
        if (changes) {
          if ((changes & this.SCARD_STATE_EMPTY) && (status.state & this.SCARD_STATE_EMPTY)) {
            console.log('Card removed')
            _this.emit('status',"removed");
            this.disconnect(this.SCARD_LEAVE_CARD, function(err) {
              if (err) {
                  console.log(err);
              } else {
                  console.log('Disconnected');
              }
            });
          } else if ((changes & this.SCARD_STATE_PRESENT) && (status.state & this.SCARD_STATE_PRESENT)) {
            // detect corrupt card and change select apdu
            console.log("Card inserted")
            _this.emit('status',"inserted");
            _this.doRead(status,reader)
          }
        }
      });

      reader.on('error', function (err) {
        console.log('Error(', this.name, '):', err.message);
      });
      
      reader.on('end', function () {
        console.log('Reader ended. Removed.',this.name);
        _this.reader = null;
        _this.has_reader = false;
        _this.emit('has_reader',false);
      });
    });
  }

  readNow() { 
    if(!this.reader)return;
    let _this = this;
    this.reader.disconnect(this.reader.SCARD_LEAVE_CARD, function(err) {
      if (err) {
          console.log(err);
      } else {
          console.log('Disconnected');
          if( ( _this.current_status.state & _this.reader.SCARD_STATE_PRESENT) === _this.reader.SCARD_STATE_PRESENT) {
            _this.doRead(_this.current_status,_this.reader);
          }
      }
    });
  }

  doRead(status,reader) {
    let _this = this;
    _this.emit('reading',true);
    if (status.atr[0] === 0x3B && status.atr[1] === 0x67) { 
      _SELECT = _SELECT2; 
      console.log('Use Select2')
    }
    
    reader.connect({ share_mode : reader.SCARD_SHARE_SHARED }, (err, protocol) => {
      if (err || !protocol) {
        console.log(protocol)
        console.log(err)
        _this.emit('reading',false);
      } else {
        setTimeout(()=>{
          _this.readData(reader,protocol);
        },1000);
        
      }
    })
  }

  pcsc_listening() {
    var _this = this;
    this.pcsc.on('error', function (err) {
      console.log('PCSC error', err.message);
      let code = _this.extractErrorCode(err.message);
      if( code === "0x8010001d" || code === "0x8010001e" ) {
        console.log('Card reader disconnected. Trying to reconnect...');
        _this.emit('status',"disconnected");
        _this.close();
        setTimeout(()=>{_this.init()},1000);
      }
    });
  }


  async readData(reader,protocol,retry) {
    let result = {};
    console.log("readData");
    
    try {
      await this.sendCommand(_INIT_SELECT,false,reader,protocol);
      result.cid = await this.sendCommand(_CID, true,reader,protocol);
      //result.personnel = await this.sendCommand(_PERSONNEL, true,reader,protocol);
      result.fullname = await this.sendCommand(_THFULLNAME, true,reader,protocol);
      //result.enfullname = await this.sendCommand(_ENFULLNAME, true,reader,protocol);
      result.dob = await this.sendCommand(_BIRTH, true,reader,protocol);
      result.gender = await this.sendCommand(_GENDER, true,reader,protocol);
      result.address = await this.sendCommand(_ADDRESS, true,reader,protocol);
      //result.personnel = await this.sendCommand(_PERSONNEL, true,reader,protocol);
      //console.log(result.personnel)
      var names = result.fullname.split('#')
      result.prefix = names[0]
      result.firstname = names[1]
      result.lastname = names[3]
      result.fullname = result.fullname.replace(/#/g, ' ').trim()
      result.address = result.address.replace(/#/g, ' ').trim()
      this.emit('read',result);
      this.emit('reading',false);
      return;
      //console.log(result)
    } catch (e) {
      this.emit('reading',false);
    }
  }

  async sendCommand(command, select,reader,protocol) {
    let data = null
    await this.transmit(command,reader,protocol)
    if (select) {
      data = await this.transmit(_SELECT.concat(command.slice(-1)),reader,protocol);
    }
    if(data) {
      return this.hex2string(data.toString('hex'));
    } else {
      return null;
    }
  }

  async transmit(command,reader,protocol) {
    return new Promise((resolve, reject) => {
      //console.log(this.protocol)
      reader.transmit(Buffer.from(command), 256, protocol, (err, data) => {
        if (err) {
          reject(err)
        }
        else {
          resolve(data)
        }
      })
    })
  }

  hex2string(input) {
    let tempHex = input
    if (tempHex.length > 4) tempHex = tempHex.slice(0, -4)
    const patt = /^[a-zA-Z0-9&@.$%\-,():`# \/]+$/
    const hex = tempHex.toString()
    let str = ''
    let tmp = ''
    for (let i = 0; i < hex.length; i += 2) {
      tmp = String.fromCharCode(parseInt(hex.substr(i, 2), 16))
      if (!tmp.match(patt)) {
        tmp = String.fromCharCode(parseInt(hex.substr(i, 2), 16) + 3424)
      }
      str += tmp
    }
    //str = str.replace(/#/g, ' ').trim()
    str = str.trim()
    return str
  }

  startsWith(str,searchString) {
    str = str+""
		return str.indexOf(searchString, 0) === 0;
  }

  close() {
    if(this.reader) {
      this.reader.close();
      this.reader = null;
    }
    if(this.pcsc) {
      this.pcsc.close();
      this.pcsc = null;
    }
  }

  extractErrorCode(err) {
    var newTxt = err.split('(');
    for (var i = 1; i < newTxt.length; i++) {
        return newTxt[i].split(')')[0];
    }
  }
}
export default NativeCardReader;