import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import ThermalPrint from "../helpers/ThermalPrint";

import authFetch from '../services/authFetch';
import Modal from './Modal';

class PackageSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			show_modal: false,
			packages: [],
			lab_categories: [],
			selectedPackages: [],
			selectedLabs: [],
			defaultLabs: [],
			allLabs: [],
			totalPrice: 0
		};

		this.saveChange = this.saveChange.bind(this);
		this.showModel = this.showModel.bind(this);
		this.hideModel = this.hideModel.bind(this);
		this.togglePackage = this.togglePackage.bind(this);
		this.toggleLab = this.toggleLab.bind(this);

		this.calcPrice = this.calcPrice.bind(this);
		this.buildDefaultLabs = this.buildDefaultLabs.bind(this);
	}

	componentDidMount() {
		var packages = [].concat(this.props.packages);
		this.setState({ selectedPackages: packages })

		authFetch.get('labs', (result) => {
			if (result.success) {
				var labs = [].concat(this.props.labs);
				// console.log(labs)
				var all_labs = [];
				result.lab_categories.map((item, index) => { all_labs = all_labs.concat(item.labs) })
				this.setState({ allLabs: all_labs, lab_categories: result.lab_categories, selectedLabs: labs },()=>{
					setTimeout(()=>{this.calcPrice(packages, labs)},10);
				})
			}
		});
	}

	calcPrice(selectedPackages, selectedLabs) {
		var result = 0;

		this.props.all_packages.map((item, index) => {
			if (selectedPackages.includes(item.id)) {
				result += (item.price) ? parseInt(item.price) : 0;
			}
		});

		this.state.allLabs.map((item, index) => {
			if (selectedLabs.includes(item.id)) {
				result += (item.price) ? parseInt(item.price) : 0;
			}
		});
		this.setState({ totalPrice: result })
	}

	buildDefaultLabs(selectedPackages) {
		var labs = [];

		this.props.all_packages.map((item, index) => {
			if (selectedPackages.includes(item.id)) {
				labs = labs.concat(item.labs.map(function (elem) { return elem.id; }));
			}
		});

		labs = labs.concat(this.props.programs_labs);
		labs = labs.filter((value, index, self) => { return self.indexOf(value) === index; });
		this.setState({ defaultLabs: labs })
	}

	togglePackage(id) {
		var selected = this.state.selectedPackages;
		var index = selected.indexOf(id);
		if (index !== -1) {
			//already in, remove
			selected.splice(index, 1);
		} else {
			//not in, add
			selected.push(id);
		}
		this.setState({ selectedPackages: selected });
		this.buildDefaultLabs(selected);
		this.calcPrice(selected, this.state.selectedLabs);
	}

	toggleLab(id) {
		var selected = this.state.selectedLabs;
		var index = selected.indexOf(id);
		if (index !== -1) {
			//already in, remove
			selected.splice(index, 1);
		} else {
			//not in, add
			selected.push(id);
		}
		this.setState({ selectedLabs: selected });
		this.calcPrice(this.state.selectedPackages, selected);
	}

	showModel() {
		var packages = [].concat(this.props.packages);
		var labs = [].concat(this.props.labs);
		this.setState({ show_modal: true, selectedPackages: packages, selectedLabs: labs })
		this.buildDefaultLabs(packages);
	}

	hideModel() {
		this.calcPrice(this.props.packages, this.props.labs);
		this.setState({ show_modal: false, selectedPackages: [], selectedLabs: [] })
	}

	saveChange() {
		this.props.update(this.state.selectedPackages, this.state.selectedLabs);
		this.calcPrice(this.state.selectedPackages, this.state.selectedLabs);
		this.setState({ show_modal: false, selectedPackages: [], selectedLabs: [] })
		this.do_print()
	}

	do_print = () => {
		let text = "";
		let addition_price = 0;
		let title = "Additional Packages";
		let subtitle = this.props.patient.fullname;
		this.props.all_packages.map((item, index) => { 
			if(this.state.selectedPackages.includes(item.id)) {
				text += "Package: "+item.name+" (฿ "+item.price+")\n";
				if(item.price)addition_price += parseInt(item.price);
			} 
		});
		if(this.state.selectedPackages.length>0 && this.state.selectedLabs>0){
			text += "\n";
		}
		this.state.allLabs.map((item, index) => { 
			if (this.state.selectedLabs.includes(item.id)) {
				text += "Lab: "+item.name+" (฿ "+item.price+")\n";
				if(item.price)addition_price += parseInt(item.price);
			}
		})
		text += "\nAdditional Price: ฿ "+addition_price+"\n";
		
		const print = new ThermalPrint();
		//ar baseUrl = window.location.protocol + "//" + window.location.host;
		var imageUrl = process.env.REACT_APP_SERVER_URL+'/'+window.app_config.logo_bw;// baseUrl+""+require("assets/images/app-logo.png");
    //console.log(imageUrl)
    print.print(imageUrl,title,subtitle,text);
		/*
		request.get({url: imageUrl}, function (err, response, body) {
			fs.writeFile("print-logo.png", body, (err) => {
				if(err) {
					console.log(err);
				} else {
					console.log("The file was saved!");
					print.print(logo,"Additional Packages",text);
				}
			}); 
		});
		*/
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="select-package">
						{(this.props.packages.length == 0 && this.props.labs.length == 0) ? (
							<div className="package-table-btn" onClick={this.showModel}>
								<img src={require('assets/images/icon-plus-white.svg')} /> {translate("buy_more_package_lab")}
								<br />
								<small>({translate("buy_more_text_cost")})</small>
							</div>
						) : (
								<div className="package-table-btn" onClick={this.showModel}>
									<div className=" package-table-btn-complex">
										<div className="package-table-btn-left">
											<span>{translate("addition_lab_package")}</span>
										</div>

										<div className="package-table-btn-mid">
											<span>{this.props.all_packages.map((item, index) => { return (this.props.packages.includes(item.id)) ? item.name : false; }).filter(el => el).join(", ")}</span>
											{this.props.packages.length > 0 && this.props.labs.length > 0 ? ', ' : ''}
											<span>{this.state.allLabs.map((item, index) => { return (this.props.labs.includes(item.id)) ? item.name : false; }).filter(el => el).join(", ")}</span>
										</div>

										<div className="package-table-btn-end">
											<img src={require('assets/images/icon-edit.svg')} />
										</div>
									</div>
									<div className="package-table-btn-price">
										<span>{translate("buy_more_text_cost")} </span> {translate("addition_price")} <strong>{this.state.totalPrice.toLocaleString()} บาท</strong>
									</div>
								</div>
							)}

						{this.state.show_modal ? (
							<div className="modal">
								<div className="modal-body-package">
									<div className="modal-body-package-header">
										<div>{translate("package")}</div>
										<div>{translate("lab")}</div>
									</div>
									<div className="modal-body-package-content">

										<ul className="modal-body-package-content-list">

											{this.props.all_packages.map(function (item, index) {
												return (<li key={index} className={"modal-body-package-content-item " + ((this.state.selectedPackages.includes(item.id)) ? 'selected' : '')} onClick={() => this.togglePackage(item.id)}>
													<div className="modal-body-package-content-item-header">
														<div className="modal-body-package-content-item-select">
															{(this.state.selectedPackages.includes(item.id)) ?
																<i className="fa fa-check-square"></i>
																:
																<i className="far fa-square"></i>
															}
														</div>
														<div className="modal-body-package-content-item-desc">
															<strong>{item.name}</strong> ({(item.price) ? parseInt(item.price).toLocaleString() + ' บาท' : ''})
										  				<br />
															{item.labs.map(function (elem) { return elem.name; }).join(", ")}
														</div>
														{item.comment ? (
															<div className="modal-body-package-content-item-help">
																<small>{item.comment}</small>
																<span className="help"><img src={require('assets/images/icon-help.svg')} width="20" /></span>
															</div>
														) : (null)}
													</div>
												</li>)
											}.bind(this)
											)}
										</ul>

										<div className="modal-body-package-content-lab">
											{this.state.lab_categories.map((item, index) => {
												return (
													<ul key={index} className="modal-body-package-content-lab-list">
														<li className="header" >{item.name}</li>
														{item.labs.map((litem, lindex) => {
															return (
																<li key={lindex} onClick={() => this.toggleLab(litem.id)}
																	className={
																		(this.state.defaultLabs.includes(litem.id) ? 'default-lab' : '')
																		+ ' ' +
																		(this.state.selectedLabs.includes(litem.id) ? 'selected-lab' : '')
																	}>
																	{(this.state.selectedLabs.includes(litem.id) || this.state.defaultLabs.includes(litem.id)) ?
																		<i className="fa fa-check-square"></i>
																		:
																		<i className="far fa-square"></i>
																	}
																	{litem.name} <small>({parseInt(litem.price).toLocaleString()}.-)</small>
																	{litem.comment ? (
																		<span className="help">
																			<div className="help-info">{litem.comment}</div>
																			<img src={require('assets/images/icon-help.svg')} width="20" />
																		</span>
																	) : (null)}
																</li>
															)
														})}
													</ul>
												)
											}
											)}
										</div>
									</div>
									<div className="modal-body-package-footer">
										<div className="modal-body-package-footer-info">
											<strong>{translate("name")}</strong> {this.props.patient.fullname}
											<br />
											<strong>{translate("age")}</strong> {this.props.patient.age}
											<div className="spacer" />
											<strong>{translate("gender")}</strong> {(this.props.patient.sex) ? <span>{translate(this.props.patient.sex)}</span> : (null)}
										</div>
										<div className="modal-body-package-footer-package">
											<div>
												{this.state.selectedPackages.length > 0 || this.state.selectedLabs.length > 0 ? (<strong>{translate("addition_lab_package")}:&nbsp;</strong>) : ''}
												{this.props.all_packages.map((item, index) => { return (this.state.selectedPackages.includes(item.id)) ? item.name : false; }).filter(el => el).join(", ")}
												{this.state.selectedPackages.length > 0 && this.state.selectedLabs.length > 0 ? ', ' : ''}
												{this.state.allLabs.map((item, index) => { return (this.state.selectedLabs.includes(item.id)) ? item.name : false; }).filter(el => el).join(", ")}
											</div>
										</div>
										<div className="modal-body-package-footer-button">
											<button className="btn-round btn-round-secondary" onClick={this.hideModel}>{translate("cancel")}</button>
											<button className="btn-round btn-round-secondary" onClick={this.do_print}>{translate("print")}</button>
											<button className="btn-secondary btn-round" onClick={this.saveChange}>{translate("confirm_package_pay")} {this.state.totalPrice.toLocaleString()} ฿</button>
										</div>
									</div>
								</div>
							</div>
						) : (null)}
					</div>
				}
			</Translate>
		);
	}
}

export default PackageSelect;